import { gql } from "@apollo/client";
import { getQueryHook } from "../getQueryHook";
import { Identified } from "../Identified/Identified";
import { List } from "../List/List";
import { InstructorFilter } from "./InstructorFilter";
import { PersonType } from "./PersonType";

export interface Person extends Identified {
  name: string;
  uid: string;
  photo: string;
  type: PersonType;
}

export const PersonFragment = gql`
  fragment PersonFragment on Person {
    _id
    name
    uid
    photo
    type
  }
`;

export const PersonQuery = gql`
  ${PersonFragment}
  query Person($id: ObjectId!) {
    person(id: $id) {
      ...PersonFragment
    }
  }
`;

export const InstructorListQuery = gql`
  ${PersonFragment}
  query InstructorList($filter: InstructorFilter!) {
    instructorList(filter: $filter) {
      total
      data {
        ...PersonFragment
      }
    }
  }
`;

export const useInstructors = getQueryHook<
  { instructorList: List<Person> },
  {
    filter: InstructorFilter;
  }
>(InstructorListQuery);

export const usePerson = getQueryHook<{ person: Person }, { id: string }>(
  PersonQuery
);
