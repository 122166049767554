import React from "react";
import { FeedbackFilter } from "../../../../GraphQL/Feedback/FeedbackFilter";
import { useFeedbackDistribution } from "./useFeedbackDistribution";
import Skeleton from "../../../../Components/Layout/Skeleton";
import Message from "../../../../Components/Feedback/Message";
import { scaleLinear } from "@visx/scale";
import { AreaClosed, LinePath } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { curveMonotoneX } from "@visx/curve";
import { Box, Typography, useColorScheme, useTheme } from "@mui/joy";
import { MdArrowForward } from "react-icons/md";
import { extractHexColor } from "../../../../Utils/getColor";
import { GridRows } from "@visx/grid";

export interface FeedbackDistrubitionProps {
  filter: FeedbackFilter;
}

const FeedbackDistrubition: React.FC<FeedbackDistrubitionProps> = ({
  filter,
}) => {
  const { palette } = useTheme();
  const { colorScheme } = useColorScheme();
  const { data, error, loading } = useFeedbackDistribution(filter);

  const counts: [number, number][] = React.useMemo(
    () =>
      data
        ? [
            [10, data.d10],
            [20, data.d20],
            [30, data.d30],
            [40, data.d40],
            [50, data.d50],
            [60, data.d60],
            [70, data.d70],
            [80, data.d80],
            [90, data.d90],
            [100, data.d100],
          ]
        : [],
    [data]
  );

  if (loading) return <Skeleton />;
  if (error) return <Message message={error.message} type='error' />;
  if (!data) return <Message message='No data found' type='error' />;

  // Dimensions
  const width = 600;
  const height = 300;
  const margin = { top: 20, right: 60, bottom: 30, left: 20 };

  // Scales
  const xScale = scaleLinear<number>({
    domain: [10, 100],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear<number>({
    domain: [0, Math.max(...counts.map((d) => d[1]))],
    range: [height - margin.bottom, margin.top],
  });

  const axisLabelProps = {
    fill: extractHexColor(palette.text.primary) || "",
  };

  const xTickValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const getMaxDataPoint = (dataPoints: [number, number][]) => {
    return Math.max(...dataPoints.map((d) => d[1]));
  };

  const maxDataPoint = getMaxDataPoint(counts);

  const getGridIntervals = (maxDataPoint: number) => {
    if (maxDataPoint <= 10) return [...Array(maxDataPoint + 1).keys()];
    if (maxDataPoint <= 50)
      return Array.from({ length: maxDataPoint / 5 + 1 }, (_, i) => i * 5);
    if (maxDataPoint <= 200)
      return Array.from({ length: maxDataPoint / 25 + 1 }, (_, i) => i * 25);
    return Array.from({ length: maxDataPoint / 50 + 1 }, (_, i) => i * 50);
  };

  const gridIntervals = getGridIntervals(maxDataPoint);

  return (
    <Box sx={{ display: "flex", alignItems: "stretch" }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-end",
          width: 24,
          paddingBottom: 8,
        }}
      >
        <Box
          sx={{
            transform: "rotate(-90deg)",
          }}
        >
          <Typography
            sx={{ fontWeight: 700 }}
            endDecorator={<MdArrowForward />}
          >
            Frequency
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>
        <svg width={width} height={height}>
          <AreaClosed
            data={counts}
            x={(d) => xScale(d[0]) || 0}
            y={(d) => yScale(d[1]) || 0}
            yScale={yScale}
            strokeWidth={1}
            stroke={extractHexColor(palette.primary[500]) || ""}
            fill={
              colorScheme === "light"
                ? extractHexColor(palette.primary[50]) || ""
                : extractHexColor(palette.neutral[800]) || ""
            }
            curve={curveMonotoneX} // Apply the curve here
          />
          <LinePath
            data={counts}
            x={(d) => xScale(d[0]) || 0}
            y={(d) => yScale(d[1]) || 0}
            strokeWidth={2}
            stroke={extractHexColor(palette.primary[500]) || ""}
            curve={curveMonotoneX} // And here
          />
          <Group left={margin.left}>
            <AxisLeft scale={yScale} tickLabelProps={() => axisLabelProps} />
          </Group>
          <Group top={height - margin.bottom}>
            <AxisBottom
              tickValues={xTickValues}
              scale={xScale}
              top={0}
              tickLabelProps={() => axisLabelProps}
              tickFormat={(value: any) => {
                const numValue = Number(value);
                if (numValue === 0) return "0";
                if (numValue === 100) return "90-100";
                return `${numValue - 10}-${numValue}`;
              }}
            />
          </Group>
          <GridRows
            scale={yScale}
            width={width - margin.right - margin.left}
            height={height - margin.top - margin.bottom}
            left={margin.left}
            stroke={palette.text.secondary}
            shapeRendering='crispEdges'
            strokeWidth={1}
            strokeOpacity={0.2}
            tickValues={gridIntervals}
          />
        </svg>
        <Box sx={{ paddingLeft: 2 }}>
          <Typography
            sx={{ fontWeight: 700 }}
            endDecorator={<MdArrowForward />}
          >
            Average Score
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FeedbackDistrubition;
