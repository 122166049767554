import { gql } from "@apollo/client";
import { getLazyQueryHook, getQueryHook } from "../getQueryHook";
import { ChatResoponse } from "./ChatResponse";
import { MessageRole } from "./MessageRole";

export interface Message {
  role: MessageRole;
  content: string;
}

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    role
    content
  }
`;

export const ChatQuery = gql`
  ${MessageFragment}
  query Chat($messages: [MessageInput!]!) {
    chat(messages: $messages) {
      messages {
        ...MessageFragment
      }
      threshold
    }
  }
`;

export const useChat = getQueryHook<
  { chat: ChatResoponse },
  { messages: Message[] }
>(ChatQuery);

export const useLazyChat = getLazyQueryHook<
  { chat: ChatResoponse },
  { messages: Message[] }
>(ChatQuery);
