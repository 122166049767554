import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from "@apollo/client";

export const getQueryHook =
  <Results, Arguments extends OperationVariables>(query: DocumentNode) =>
  (options: QueryHookOptions<Results, Arguments> = {}) =>
    useQuery<Results, Arguments>(query, options);

export const getLazyQueryHook =
  <Results, Arguments extends OperationVariables>(query: DocumentNode) =>
  (options: QueryHookOptions<Results, Arguments> = {}) =>
    useLazyQuery<Results, Arguments>(query, options);
