import { Box, Typography, useTheme } from "@mui/joy";
import React from "react";

export interface BulletListProps {
  children: string[];
}

const BulletList: React.FC<BulletListProps> = ({ children }) => {
  const { palette } = useTheme();
  return (
    <Box sx={{ maxWidth: 500 }}>
      {children.map((child, i) => (
        <Box key={i} sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
          <Box sx={{ paddingTop: "8px" }}>
            <Box
              sx={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                backgroundColor: palette.neutral[100],
              }}
            />
          </Box>
          <Typography>{child}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default BulletList;
