import React from "react";
import { Box, CircularProgress, useTheme } from "@mui/joy";

const Loading: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: palette.background.body,
      }}
    >
      <CircularProgress color='neutral' />
    </Box>
  );
};

export default Loading;
