import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React, { PropsWithChildren } from "react";
import { auth } from "../../Hooks/auth/auth";
import useAuthState from "../../Hooks/auth/useAuthState";

const AppDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user] = useAuthState(auth);

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  const asyncAuthLink = setContext(
    (request) =>
      new Promise((success, fail) => {
        const tenant = localStorage.getItem("tenant");
        if (!user) return success({});
        else {
          user.getIdToken().then((authorization) => {
            success({
              headers: { authorization, tenant: tenant || undefined },
            });
          });
        }
      })
  );

  const client = new ApolloClient({
    link: concat(asyncAuthLink, httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AppDataProvider;
