import { Box, Button, Divider, Tooltip, useTheme } from "@mui/joy";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../../../../Components/Dashboard";
import { useCollege } from "../../../../GraphQL/College/College";
import BreadCrumbs from "../BreadCrumbs";
import Chat from "../Chat";
import FeedbackDistrubition from "../FeedbackDistribution";
import PageTitle from "../PageTitle";
import ScoreChart from "../ScoreChart";
import SectionCard from "../SectionCard";
import Strengths from "../Strengths";

export const pageSpacing = 4;

export interface CollegePageProps {}

const CollegePage: React.FC<CollegePageProps> = ({}) => {
  const { palette } = useTheme();
  const { college: id } = useParams();
  const nav = useNavigate();

  const { data, error, loading } = useCollege({
    variables: {
      id: id || "",
    },
  });

  const college = data?.college || null;

  const [scrolled, setScrolled] = React.useState(false);

  return (
    <Dashboard
      error={error}
      loading={loading}
      onScroll={(e) => {
        setScrolled(e);
      }}
    >
      {college && (
        <Box sx={{ display: "flex", flexFlow: "column", gap: pageSpacing }}>
          <Box
            sx={{
              position: "sticky", // Set the component to be sticky
              top: 0, // This means the component will stick to the top
              zIndex: 1, // Optional: depending on your other components
              backgroundColor: palette.background.body,
              boxShadow: scrolled
                ? "0px 8px 6px -6px rgba(0,0,0,0.1)"
                : undefined,
            }}
          >
            <Box p={2} />
            <BreadCrumbs
              elements={[{ label: college.name, path: `/${college._id}` }]}
            />
            <Box p={1} />
            <PageTitle
              subtitle={`Lead by ${college.leader.name}`}
              avatars={[
                {
                  alt: college.name,
                  src: `https://ui-avatars.com/api/?name=${encodeURI(
                    college.name
                  )}&bold=true&color=fff&background=CC0000`,
                },
                {
                  alt: college.leader.name,
                  src: college.leader.photo,
                },
              ]}
            >
              {college.name}
            </PageTitle>
            <Box p={1} />
            <Divider />
          </Box>
          <PageTitle level={2}>Highlights</PageTitle>
          <Strengths filter={{ college: college._id }} />
          <Box p={0} />
          <PageTitle level={2}>Average Feedback Scores</PageTitle>
          <ScoreChart filter={{ college: college._id, skip: 0, take: 1 }} />
          <Box p={0} />
          <PageTitle level={2}>Feedback Distribution</PageTitle>
          <FeedbackDistrubition
            filter={{ college: college._id, skip: 0, take: 1 }}
          />
          <Box p={0} />
          <Divider />
          <Box>
            <PageTitle level={2}>Departments</PageTitle>
            <Box p={2} />
            <Box>
              {college.departments.map((department) => (
                <SectionCard
                  key={department._id}
                  title={department.name}
                  subtitle={`Lead by ${department.leader.name}`}
                  avatars={[
                    {
                      alt: department.name,
                      src: `https://ui-avatars.com/api/?name=${encodeURI(
                        department.name
                      )}&bold=true&color=fff&background=CC0000`,
                    },
                    {
                      alt: department.leader.name,
                      src: department.leader.photo,
                    },
                  ]}
                  onClick={() => nav(`/${college._id}/${department._id}`)}
                />
              ))}
            </Box>
          </Box>
          <Box p={10} />
          <Chat filter={{ college: college._id }}>
            {(loading, error, onClick) => (
              <Box
                sx={{
                  position: "fixed",
                  bottom: "72px",
                  right: "72px",
                  zIndex: 10,
                }}
              >
                <Tooltip title={error ? error.message : ""}>
                  <Button
                    size='lg'
                    onClick={onClick}
                    loading={loading}
                    disabled={Boolean(error)}
                  >
                    Ask a question
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Chat>
        </Box>
      )}
    </Dashboard>
  );
};

export default CollegePage;
