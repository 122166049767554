import { gql } from "@apollo/client";
import { getQueryHook } from "../getQueryHook";
import { Message, MessageFragment } from "../Message/Message";
import { FeedbackSummaryFilter } from "./FeedbackSummaryFilter";

export interface FeedbackSummary {
  strengths: string[];
  weaknesses: string[];
  initial_messages: Message[];
}

export const FeedbackSummaryFragment = gql`
  fragment FeedbackSummaryFragment on FeedbackSummary {
    strengths
    weaknesses
    initial_messages {
      ...MessageFragment
    }
  }
`;

export const FeedbackSummaryQuery = gql`
  ${MessageFragment}
  ${FeedbackSummaryFragment}
  query FeedbackSummary($filter: FeedbackSummaryFilter!) {
    feedbackSummary(filter: $filter) {
      ...FeedbackSummaryFragment
    }
  }
`;

export const useFeedbackSummary = getQueryHook<
  { feedbackSummary: FeedbackSummary },
  { filter: FeedbackSummaryFilter }
>(FeedbackSummaryQuery);
