import { Box, useTheme } from "@mui/joy";
import React from "react";

export interface SkeletonProps {
  height?: number;
}

const Skeleton: React.FC<SkeletonProps> = ({ height = 100 }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        height,
        width: "100%",
        borderRadius: "8px",
        backgroundImage: `
          linear-gradient(90deg, ${palette.background.level2} 25%, ${palette.background.level1} 50%, ${palette.background.level2} 75%)
        `,
        backgroundSize: "200% 100%", // To ensure that we only see half of the gradient at a time
        animation: "shift 2s infinite", // 2s duration and infinite loop
        "@keyframes shift": {
          "0%": {
            backgroundPosition: "100% 0",
          },
          "100%": {
            backgroundPosition: "0 0",
          },
        },
      }}
    />
  );
};

export default Skeleton;
