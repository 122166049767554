import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppDataProvider from "./Providers/AppDataProvider";
import AppThemeProvider from "./Providers/AppThemeProvider";

import "@fontsource-variable/montserrat";
import AppRouter from "./Components/AppRouter";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppThemeProvider>
        <AppDataProvider>
          <AppRouter />
        </AppDataProvider>
      </AppThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
