import { Box, Typography, useTheme } from "@mui/joy";
import React from "react";
import { extractHexColor, getColor } from "../../../../../../Utils/getColor";

export interface LinearBarProps {
  value: number;
  max: number;
}

const LinearBar: React.FC<LinearBarProps> = ({ value, max }) => {
  const { palette } = useTheme();
  const color = getColor(0, max, value, [
    extractHexColor(palette.warning[500]) || "",
    extractHexColor(palette.success[500]) || "",
  ]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 8,
            borderRadius: 4,
            backgroundColor: palette.neutral[300],
          }}
        >
          <Box
            sx={{
              width: `${(value / max) * 100}%`,
              height: 8,
              borderRadius: 4,
              backgroundColor: color,
            }}
          />
        </Box>
        <Typography sx={{ ml: 1, minWidth: 40 }}>
          {value.toFixed(0)}/100
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearBar;
