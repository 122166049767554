import { gql } from "@apollo/client";
import { Division } from "../Division/Division";
import { getQueryHook } from "../getQueryHook";
import { List } from "../List/List";
import { PersonFragment } from "../Person/Person";
import { DepartmentFilter } from "./DepartmentFilter";

export interface Department extends Division {}

export const DepartmentFragment = gql`
  fragment DepartmentFragment on Department {
    _id
    name
    leader {
      ...PersonFragment
    }
  }
`;

export const DepartmentListQuery = gql`
  ${DepartmentFragment}
  ${PersonFragment}
  query DepartmentList($filter: DepartmentFilter!) {
    departmentList(filter: $filter) {
      total
      data {
        ...DepartmentFragment
      }
    }
  }
`;

export const DepartmentQuery = gql`
  ${DepartmentFragment}
  ${PersonFragment}
  query Department($id: ObjectId!) {
    department(id: $id) {
      ...DepartmentFragment
    }
  }
`;

export const useDepartments = getQueryHook<
  { departmentList: List<Department> },
  { filter: DepartmentFilter }
>(DepartmentListQuery);

export const useDepartment = getQueryHook<
  { department: Department },
  { id: string }
>(DepartmentQuery);
