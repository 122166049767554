import { Route, Routes } from "react-router-dom";
import CollegePage from "./components/CollegePage";
import CollegeSelection from "./components/CollegeSelection";
import DepartmentPage from "./components/DepartmentPage";
import InstructorPage from "./components/InstructorPage";

const Datagrid: React.FC = () => {
  return (
    <Routes>
      <Route index element={<CollegeSelection />} />
      <Route path=':college' element={<CollegePage />} />
      <Route path=':college/:department' element={<DepartmentPage />} />
      <Route
        path=':college/:department/:instructor'
        element={<InstructorPage />}
      />
    </Routes>
  );
};

export default Datagrid;
