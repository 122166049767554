import { Box, Button, useColorScheme } from "@mui/joy";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../../AppRouter/routes";

const RouteSection: React.FC = () => {
  const { pathname } = useLocation();
  const { mode } = useColorScheme();
  const nav = useNavigate();

  return (
    <Box sx={{ display: "flex", flexFlow: "column", gap: 0.5, p: 1 }}>
      {routes.map((route, index) => (
        <Button
          onClick={() => nav(route.path)}
          sx={{
            justifyContent: "flex-start",
            gap: 1,
          }}
          size='sm'
          color='neutral'
          variant={
            route.path === "/"
              ? pathname === "/"
                ? "solid"
                : "soft"
              : route.path === "/*"
              ? pathname === "/*"
                ? "solid"
                : "soft"
              : pathname.includes(route.path)
              ? mode === "dark"
                ? "solid"
                : "soft"
              : "plain"
          }
          key={"route_" + index}
        >
          {route.label}
        </Button>
      ))}
    </Box>
  );
};

export default RouteSection;
