import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Typography,
  useTheme,
} from "@mui/joy";
import React from "react";
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
import { useNavigate } from "react-router";
import { auth } from "../../Hooks/auth/auth";
import useAuthState from "../../Hooks/auth/useAuthState";
import useSignInWithEmailAndPassword from "../../Hooks/auth/useSignInWithEmailAndPassword";
import Loading from "../Loading";
import { ReactComponent as Logo } from "./logo.svg";

const Login: React.FC = () => {
  const { palette, shadow } = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const nav = useNavigate();

  const [user, loading] = useAuthState(auth);
  const [login, loginUser, loginLoading, error] =
    useSignInWithEmailAndPassword(auth);

  const [method, setMethod] = React.useState({
    email: "",
    password: "",
  });

  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: palette.background.level1,
      }}
    >
      <Box
        sx={{
          background: palette.background.body,
          p: 2,
          borderRadius: "8px",
          boxShadow: shadow.md,
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            paddingLeft: "8px",
          }}
        >
          <Logo style={{ height: "32px" }} />
          <Typography sx={{ textAlign: "center" }} level='h2'>
            Spark Labs
          </Typography>
        </Box>
        <Box sx={{ p: 1 }} />
        <Divider />
        <Box sx={{ p: 1 }} />
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            autoFocus
            variant='soft'
            color='neutral'
            value={method.email}
            onChange={(e) => setMethod({ ...method, email: e.target.value })}
          />
        </FormControl>
        <Box sx={{ p: 1 }} />
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            variant='soft'
            color='neutral'
            type={showPassword ? "text" : "password"}
            value={method.password}
            onChange={(e) => setMethod({ ...method, password: e.target.value })}
            endDecorator={
              <Box>
                <IconButton
                  variant='soft'
                  color='neutral'
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                </IconButton>
              </Box>
            }
          />
        </FormControl>
        <Box sx={{ p: 1.5 }} />
        <Button
          disabled={
            loginLoading ||
            method.email.length === 0 ||
            method.password.length === 0
          }
          color='neutral'
          onClick={() => {
            login(method.email, method.password);
          }}
          loading={loginLoading}
          fullWidth
          variant='outlined'
        >
          Login
        </Button>
        <Box
          sx={{
            position: "absolute",
            bottom: -40,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {error && (
            <Typography color='danger'>Incorrect email or password</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
