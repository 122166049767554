import React from "react";
import { Route, Routes } from "react-router-dom";
import { auth } from "../../Hooks/auth/auth";
import useAuthState from "../../Hooks/auth/useAuthState";
import Loading from "../../Scenes/Loading";
import Login from "../../Scenes/Login";
import { routes } from "./routes";

const AppRouter: React.FC = () => {
  const [user, loading] = useAuthState(auth);

  if (loading) return <Loading />;
  else if (!user) return <Login />;
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          path={route.path}
          element={route.element}
          key={"route_" + index}
        />
      ))}
    </Routes>
  );
};

export default AppRouter;
