import {
  Avatar,
  AvatarGroup,
  AvatarProps,
  Box,
  Typography,
  useTheme,
} from "@mui/joy";
import React from "react";

export interface SectionCardProps {
  title: string;
  subtitle?: string;
  avatars?: AvatarProps[];
  onClick?: () => void;
}

const SectionCard: React.FC<SectionCardProps> = ({
  title,
  subtitle,
  avatars = [],
  onClick = () => {},
}) => {
  const { palette } = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: palette.background.level1,
          cursor: "pointer",
        },
        gap: 2,
      }}
    >
      <AvatarGroup>
        {avatars.map((avatar, i) => (
          <Avatar {...avatar} key={"av_" + i} />
        ))}
      </AvatarGroup>
      <Box>
        <Typography level='h6' sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Typography level='body2'>{subtitle}</Typography>
      </Box>
    </Box>
  );
};

export default SectionCard;
