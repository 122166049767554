import {
  Box,
  CircularProgress,
  LinearProgress,
  Modal,
  ModalClose,
  Sheet,
  Typography,
  useTheme,
} from "@mui/joy";
import React from "react";
import { ReactElement } from "react";
import { useFeedbackSummary } from "../../../../GraphQL/FeedbackSummary/FeedbackSummary";
import { FeedbackSummaryFilter } from "../../../../GraphQL/FeedbackSummary/FeedbackSummaryFilter";
import { Message, useLazyChat } from "../../../../GraphQL/Message/Message";
import { MessageRole } from "../../../../GraphQL/Message/MessageRole";
import ChatEntry from "./components/ChatBox/components/ChatEntry";
import ChatOptions from "./components/ChatBox/components/ChatSection/components/ChatOptions";
import MessageRender from "./components/ChatBox/components/ChatSection/components/MessageRender";

export interface ChatProps {
  filter: FeedbackSummaryFilter;
  children: (
    loading: boolean,
    error: Error | null | undefined,
    onClick: () => void
  ) => ReactElement;
}

const Chat: React.FC<ChatProps> = ({ filter, children }) => {
  const { palette, shadow } = useTheme();

  const [open, setOpen] = React.useState(false);

  const [threshold, setThreshold] = React.useState(0);
  const [messages, setMessages] = React.useState<Message[]>([]);

  // ref to a div that should scroll to bottom whenever the messages change
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  const { data, error, loading } = useFeedbackSummary({
    variables: {
      filter,
    },
    onCompleted: (data) => {
      setMessages(
        data.feedbackSummary.initial_messages.map((m) => ({
          role: m.role,
          content: m.content,
        }))
      );
    },
  });

  const [messageError, setMessageError] = React.useState<
    Error | null | undefined
  >(null);

  const [sendChat, { loading: chatLoading }] = useLazyChat({
    onCompleted: (data) => {
      setMessages(data.chat.messages);
      setThreshold(data.chat.threshold);
    },
    onError: (error) => {
      setMessageError(error);
    },
  });

  return (
    <React.Fragment>
      {children(loading, error, () => {
        setOpen(true);
      })}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            maxHeight: "85vh",
            maxWidth: "1000px",
            background: palette.background.body,
            borderRadius: "8px",
            boxShadow: shadow.lg,
            border: `1px solid ${palette.divider}`,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              borderBottom: `1px solid ${palette.divider}`,
              overflow: "hidden",
            }}
          >
            <Typography level='h5' sx={{ fontWeight: 700 }}>
              Ask questions about this data
            </Typography>
            <Box>
              <Typography level='body3'>Capacity</Typography>
              <LinearProgress
                color='neutral'
                size='sm'
                determinate
                value={threshold}
              />
            </Box>
            <ModalClose />
          </Box>
          <Box
            ref={scrollRef}
            sx={{
              flex: 1,
              overflow: "auto",
              maxHeight: "70vh",
              position: "relative",
              minHeight: "300px",
            }}
          >
            {messages.slice(4).length == 0 && (
              <ChatOptions
                onClick={(option) => {
                  setMessages([
                    ...messages.map((m) => ({
                      role: m.role,
                      content: m.content,
                    })),
                    {
                      role: MessageRole.user,
                      content: option,
                    },
                  ]);
                  sendChat({
                    variables: {
                      messages: [
                        ...messages.map((m) => ({
                          role: m.role,
                          content: m.content,
                        })),
                        {
                          role: MessageRole.user,
                          content: option,
                        },
                      ],
                    },
                  });
                }}
              />
            )}
            {messages.slice(4).map((message) => (
              <MessageRender message={message} key={message.content} />
            ))}
          </Box>
          <Box
            sx={{
              borderTop: `1px solid ${palette.divider}`,
              p: 2,
              background: palette.background.level2,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <ChatEntry
              loading={chatLoading || loading}
              onSend={(message) => {
                setMessages([
                  ...messages.map((m) => ({
                    role: m.role,
                    content: m.content,
                  })),
                  {
                    role: MessageRole.user,
                    content: message,
                  },
                ]);
                sendChat({
                  variables: {
                    messages: [
                      ...messages.map((m) => ({
                        role: m.role,
                        content: m.content,
                      })),
                      {
                        role: MessageRole.user,
                        content: message,
                      },
                    ],
                  },
                });
              }}
            />
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Chat;
