import { Box, useTheme } from "@mui/joy";
import React, { PropsWithChildren } from "react";

const Screen: React.FC<PropsWithChildren> = ({ children }) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        background: palette.background.body,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexFlow: "column",
        overflow: "hidden",
      }}
    >
      {children}
    </Box>
  );
};

export default Screen;
