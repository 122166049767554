import { gql } from "@apollo/client";
import { FeedbackFilter } from "../Feedback/FeedbackFilter";
import { getQueryHook } from "../getQueryHook";

export interface ScoreSummary {
  average: number;
  instructor: number;
  course: number;
  grading: number;
  expectations: number;
}

export const ScoreSummaryFragment = gql`
  fragment ScoreSummaryFragment on ScoreSummary {
    average
    instructor
    course
    grading
    expectations
  }
`;

export const ScoreSummaryQuery = gql`
  query ScoreSummary($filter: FeedbackFilter!) {
    scoreSummary(filter: $filter) {
      average
      course
      expectations
      grading
      instructor
    }
  }
`;

export const useScoreSummary = getQueryHook<
  { scoreSummary: ScoreSummary },
  { filter: FeedbackFilter }
>(ScoreSummaryQuery);
