import { Box, Button, useColorScheme, Menu, MenuItem } from "@mui/joy";
import React from "react";
import { MdDarkMode, MdLightMode, MdLogout } from "react-icons/md";
import { auth } from "../../../../../../Hooks/auth/auth";
import useAuthState from "../../../../../../Hooks/auth/useAuthState";
import useSignOut from "../../../../../../Hooks/auth/useSignOut";

const Controls: React.FC = () => {
  const [user] = useAuthState(auth);
  const [signout] = useSignOut(auth);
  const { mode, setMode } = useColorScheme();
  const ref = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ p: 1, display: "flex", flexFlow: "column" }}>
      <Box>
        <Button
          fullWidth
          onClick={() => {
            setMode(mode === "light" ? "dark" : "light");
          }}
          color='neutral'
          variant='plain'
          size='sm'
        >
          {mode === "light" ? <MdDarkMode /> : <MdLightMode />}
        </Button>
      </Box>
      {user && (
        <Button
          ref={ref}
          color='neutral'
          variant='plain'
          size='sm'
          onClick={() => setOpen(true)}
        >
          {user.displayName || user.email || "Anonymous User"}
        </Button>
      )}
      <Menu
        placement='top'
        sx={{ width: "284px" }}
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
      >
        <MenuItem onClick={() => signout()}>
          Logut
          <Box sx={{ flex: 1 }} />
          <Box sx={{ paddingLeft: "8px", display: "flex" }}>
            <MdLogout />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Controls;
