import { Box, CircularProgress, IconButton, Input, useTheme } from "@mui/joy";
import React from "react";
import { MdSend } from "react-icons/md";

export interface ChatEntryProps {
  onSend: (message: string) => void;
  loading: boolean;
}

const ChatEntry: React.FC<ChatEntryProps> = ({ onSend, loading }) => {
  const { palette, shadow } = useTheme();

  const [message, setMessage] = React.useState<string>("");

  return (
    <Input
      autoFocus
      size='lg'
      sx={{
        boxShadow: shadow.md,
      }}
      fullWidth
      placeholder='Send a message'
      value={message}
      onChange={(event) => {
        if (!loading) setMessage(event.target.value);
      }}
      onKeyPress={(event) => {
        if (event.key === "Enter" && !loading) {
          onSend(message);
          setMessage("");
        }
      }}
      endDecorator={
        <Box sx={{ display: "flex" }}>
          {loading ? (
            <CircularProgress size='sm' />
          ) : (
            <IconButton
              disabled={!message || loading}
              onClick={() => {
                if (!loading) {
                  onSend(message);
                  setMessage("");
                }
              }}
            >
              <MdSend />
            </IconButton>
          )}
        </Box>
      }
    />
  );
};

export default ChatEntry;
