import { Box, Button } from "@mui/joy";
import React from "react";

export interface ChatOptionsProps {
  onClick: (message: string) => void;
}

const ChatOptions: React.FC<ChatOptionsProps> = ({ onClick }) => {
  const options = [
    "What are the most common issues?",
    "Show me a student who is frustrated.",
    "How consistent is the feedback?",
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          gap: 1,
          alignItems: "center",
        }}
      >
        {options.map((option, index) => (
          <Button
            variant='outlined'
            color='neutral'
            fullWidth
            key={index}
            onClick={() => onClick(option)}
          >
            {option}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default ChatOptions;
