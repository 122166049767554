import { gql } from "@apollo/client";
import { getQueryHook } from "../getQueryHook";
import { Identified } from "../Identified/Identified";
import { Person } from "../Person/Person";
import { Semester } from "../Semester/Semester";
import { FeedbackFilter } from "./FeedbackFilter";
import { FeedbackScorecard } from "./FeedbackScorecard/FeedbackScorecard";

export interface Feedback extends Identified {
  college: { _id: string; name: string };
  department: { _id: string; name: string };
  instructor: Person;
  course: { _id: string; name: string };
  year: number;
  semester: Semester;
  listing: { _id: string };
  scorecard: FeedbackScorecard;
}

export interface FeedbackSummary extends Identified {
  year: number;
  semester: Semester;
  scorecard: FeedbackScorecard;
}

export const FeedbackFragment = gql`
  fragment FeedbackFragment on Feedback {
    _id
    college {
      _id
      name
    }
    department {
      _id
      name
    }
    instructor {
      ...PersonFragment
    }
    course {
      _id
      name
    }
    year
    semester
    listing {
      _id
    }
    scorecard {
      ...FeedbackScorecardFragment
    }
  }
`;

export const FeedbackSummaryFragment = gql`
  fragment FeedbackSummaryFragment on FeedbackSummary {
    year
    semester
    scorecard {
      ...FeedbackScorecardFragment
    }
  }
`;

export const FeedbackCountQuery = gql`
  query FeedbackList($filter: FeedbackFilter!) {
    feedbackList(filter: $filter) {
      total
    }
  }
`;

export const useFeedbackCount = getQueryHook<
  {
    feedbackList: { total: number };
  },
  {
    filter: FeedbackFilter;
  }
>(FeedbackCountQuery);
