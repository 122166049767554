import { Avatar, Box, Typography, useTheme } from "@mui/joy";
import React from "react";
import { Message } from "../../../../../../../../../../GraphQL/Message/Message";
import { MessageRole } from "../../../../../../../../../../GraphQL/Message/MessageRole";
import { auth } from "../../../../../../../../../../Hooks/auth/auth";
import useAuthState from "../../../../../../../../../../Hooks/auth/useAuthState";
import ChatValue from "../ChatValue";

export interface MessageRenderProps {
  message: Message;
}

const MessageRender: React.FC<MessageRenderProps> = ({ message }) => {
  const { palette } = useTheme();
  const [user] = useAuthState(auth);
  return (
    <Box
      sx={{
        background:
          message.role === MessageRole.user
            ? undefined
            : palette.background.level1,
        display: "flex",
      }}
    >
      <Box sx={{ p: 2 }}>
        {message.role === MessageRole.user ? (
          <Avatar size='sm' src={user?.photoURL || ""}></Avatar>
        ) : (
          <Avatar
            size='sm'
            src={
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAYFBMVEX////MAADIAAD98vLfhobmnJz01tbSLy//+vr99vbdfHz87u7fhITjkJDRIyPTMzPooqLyy8vz0dHpp6f229v55eXuubnOExPPHR3RKCjqrKz34uLvvr7ww8Pll5fyzMzgbKszAAAB9UlEQVR4nO3cS1PUQBSA0WtmVGAYXj5ABfn//1LdkakKkM7th1Xn26Zyu09lkfQmEZIkSZIkSZIkSZIkSYN0vkvrZHLe4PNNwosprcNs8CFv8MUm4f5DWvOHuMsbvCckzIqwOELCtAiLIyRMi7A4QsK0CIsjJEyLsDhCwrQIiyMkTIuwOELCtAiLIyRMi7A4QsK0CIsjfL3LvI1UE15uEh6vPr/sYc3K1/NbT4QPs6vXq0w/Xt56ddwkPOlmzT5WTV4zePqaaZr3cQzhp0q8ICR8LULCrAgJlyMkzIqQcDlCwqwICZcjJMyKkHA5QsKsCAmXIyTMipBwOULCrAgJlyMkzIqQcDlCwqwICZcjJMyKkHA5QsKsCOfCs/f3fwqrRUhI2D9CQsL+ERIS9o9wSz+n3rp/Tb/qCWM/AHF6rAgcgTg9VQVGHDsTqwN7ExsA+xKn2wbAiN/diI2A/YjTcyPg3zd/F2JDYB/idNcQGHHTntgW2J5Y82N0DGJ7YMSXlsQewKbEPsCGh6mKP2gbg9gP2IjYE9iEWPNE/56+1yb2BlYnfuvti8rEEYBViWMAKxJHAUY81iEe3l65WVWIIwEjntKJ92MBKxBHA0bcphLvd2+v2LznROKQwIi7KatBgRFnu6R6QyRJkiRJkiRJkiRJksr6AxK4VGr/2e3qAAAAAElFTkSuQmCC"
            }
          ></Avatar>
        )}
      </Box>
      <Box sx={{ p: 2, paddingRight: 10 }}>
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          {message.content}
        </Typography>
      </Box>
    </Box>
  );
};

export default MessageRender;
