import { Box, Typography } from "@mui/joy";
import React from "react";
import Message from "../../../../Components/Feedback/Message";
import Skeleton from "../../../../Components/Layout/Skeleton";
import { FeedbackFilter } from "../../../../GraphQL/Feedback/FeedbackFilter";
import { useScoreSummary } from "../../../../GraphQL/ScoreSummary/ScoreSummary";
import LinearBar from "./components/LinearBar";

export interface ScoreChartProps {
  filter: FeedbackFilter;
}

const ScoreChart: React.FC<ScoreChartProps> = ({ filter }) => {
  const { data, error, loading } = useScoreSummary({
    variables: {
      filter,
    },
  });

  if (loading) return <Skeleton />;
  if (error) return <Message message={error.message} type='error' />;
  if (!data) return <Message message='No data found' type='error' />;

  const summary = data.scoreSummary;

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Typography>Course Recommendation</Typography>
      <LinearBar max={100} value={summary.course} />
      <Box p={1} />
      <Typography>Course Expectations</Typography>
      <LinearBar max={100} value={summary.expectations} />
      <Box p={1} />
      <Typography>Grading Clarity</Typography>
      <LinearBar max={100} value={summary.grading} />
      <Box p={1} />
      <Typography>Instructor Recommendation</Typography>
      <LinearBar max={100} value={summary.instructor} />
    </Box>
  );
};

export default ScoreChart;
