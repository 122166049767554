import { Box, Typography, useTheme } from "@mui/joy";
import React from "react";

export interface MessageProps {
  message: string;
  type: "error" | "success" | "warning" | "info";
}

const Message: React.FC<MessageProps> = ({ message, type }) => {
  const { palette } = useTheme();
  const color =
    palette[
      type === "error"
        ? "danger"
        : type === "success"
        ? "success"
        : type === "warning"
        ? "warning"
        : "info"
    ];
  return (
    <Box
      sx={{
        backgroundColor: color[50],
        color: color[500],
        padding: 2,
        borderRadius: 2,
        border: `1px solid ${color[500]}`,
      }}
    >
      <Typography level='body1'>{message}</Typography>
    </Box>
  );
};

export default Message;
