import { Box, Typography } from "@mui/joy";
import React from "react";
import Logo from "../../../../../Logo";

const Header: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        p: 1.5,
        paddingBottom: 0,
      }}
    >
      <Logo style={{ height: "24px" }} />
      <Typography level='h5'>Spark Labs</Typography>
    </Box>
  );
};

export default Header;
