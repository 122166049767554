import { Box, Divider, useTheme } from "@mui/joy";
import React from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../../../Components/Dashboard";
import { useColleges } from "../../../../GraphQL/College/College";
import BreadCrumbs from "../BreadCrumbs";
import PageTitle from "../PageTitle";
import SectionCard from "../SectionCard";

export interface CollegeSelectionProps {}

const CollegeSelection: React.FC<CollegeSelectionProps> = ({}) => {
  const nav = useNavigate();
  const { palette } = useTheme();
  const { data, error, loading } = useColleges({
    variables: {
      filter: {
        skip: 0,
        take: 100,
      },
    },
  });
  const colleges = data?.collegeList.data || [];

  return (
    <Dashboard error={error} loading={loading}>
      <Box
        sx={{
          position: "sticky", // Set the component to be sticky
          top: 0, // This means the component will stick to the top
          zIndex: 1, // Optional: depending on your other components
          backgroundColor: palette.background.body,
        }}
      >
        <Box p={2} />
        <BreadCrumbs elements={[]} />
        <Box p={1} />
        <PageTitle>Select a College</PageTitle>
        <Box p={1} />
        <Divider />
      </Box>
      <Box p={2} />
      {colleges.map((college) => (
        <SectionCard
          onClick={() => nav(college._id)}
          title={college.name}
          subtitle={`Lead by ${college.leader.name}`}
          avatars={[
            {
              alt: college.name,
              src: `https://ui-avatars.com/api/?name=${encodeURI(
                college.name
              )}&bold=true&color=fff&background=CC0000`,
            },
            {
              alt: college.leader.name,
              src: college.leader.photo,
            },
          ]}
          key={college._id}
        />
      ))}
    </Dashboard>
  );
};

export default CollegeSelection;
