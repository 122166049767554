import { Box, LinearProgress, Typography } from "@mui/joy";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useRef } from "react";
import Screen from "../Screen";
import Sidebar from "./components/Sidebar";

export interface DashboardProps {
  error?: Error | null | undefined;
  loading?: boolean;
  onScroll?: (scrolled: boolean) => void;
}

const Dashboard: React.FC<PropsWithChildren<DashboardProps>> = ({
  error,
  loading,
  children,
  onScroll,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const scrolled = ref.current.scrollTop > 10;
        setIsScrolled(scrolled);
        if (onScroll) onScroll(scrolled);
      }
    };

    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onScroll]);

  return (
    <Screen>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "stretch",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <Box
          sx={{
            flex: 1,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            p: 3,
            paddingBottom: 6,
            paddingTop: 0,
            overflow: "auto",
          }}
          ref={ref}
        >
          {loading && (
            <Box sx={{ position: "absolute", top: 0, left: 0, right: 0 }}>
              <LinearProgress color='neutral' />
            </Box>
          )}
          <Box sx={{ flex: 1, maxWidth: 1200 }}>
            {error ? <Typography>{error.message}</Typography> : children}
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};

export default Dashboard;
