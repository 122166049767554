import { Box, Button, ButtonProps } from "@mui/joy";
import React from "react";
import { MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export interface BreadCrumbsProps {
  elements: {
    label: string;
    path: string;
  }[];
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ elements }) => {
  const nav = useNavigate();

  const btnProps: ButtonProps = {
    variant: "plain",
    color: "neutral",
  };

  return (
    <Box>
      <Button
        endDecorator={elements.length > 0 ? <MdArrowForward /> : undefined}
        onClick={() => nav("/")}
        {...btnProps}
      >
        Home
      </Button>
      {elements.map((element, index) => (
        <Button
          onClick={() => nav(element.path)}
          key={index}
          {...btnProps}
          endDecorator={
            index === elements.length - 1 ? undefined : <MdArrowForward />
          }
        >
          {element.label}
        </Button>
      ))}
    </Box>
  );
};

export default BreadCrumbs;
