export const getColor = (
  min: number,
  max: number,
  value: number,
  colors: string[]
): string => {
  // Clamp value between min and max
  value = Math.min(Math.max(value, min), max);

  // Get the relative position of the value within the range
  let position = ((value - min) / (max - min)) * (colors.length - 1);

  // Calculate the indices of the two colors to interpolate between
  let index1 = Math.floor(position);
  let index2 = Math.min(index1 + 1, colors.length - 1);

  // If the position matches exactly on a color index, return that color
  if (index1 === index2) {
    return colors[index1];
  }

  // Calculate the weight of the second color based on the position
  let weight2 = position - index1;
  let weight1 = 1 - weight2;

  // Interpolate between the two colors
  let color1 = hexToRgb(colors[index1]);
  let color2 = hexToRgb(colors[index2]);

  let r = Math.round(color1[0] * weight1 + color2[0] * weight2);
  let g = Math.round(color1[1] * weight1 + color2[1] * weight2);
  let b = Math.round(color1[2] * weight1 + color2[2] * weight2);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

// Helper function to convert HEX color to RGB
const hexToRgb = (hex: string): [number, number, number] => {
  let bigint = parseInt(hex.slice(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
};

/**
 * Extracts the first HEX color code from a given string.
 * @param input The input string to extract from.
 * @returns The extracted HEX color code or null if none found.
 */
export const extractHexColor = (input: string): string | null => {
  // Regular expression to match HEX color codes
  const regex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/;

  const match = input.match(regex);

  return match ? match[0] : null;
};
