import { Box, Button, Divider, Tooltip, useTheme } from "@mui/joy";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../../../../Components/Dashboard";
import Skeleton from "../../../../Components/Layout/Skeleton";
import { useCollege } from "../../../../GraphQL/College/College";
import { useDepartment } from "../../../../GraphQL/Department/Department";
import { useInstructors } from "../../../../GraphQL/Person/Person";
import BreadCrumbs from "../BreadCrumbs";
import Chat from "../Chat";
import { pageSpacing } from "../CollegePage";
import FeedbackDistrubition from "../FeedbackDistribution";
import PageTitle from "../PageTitle";
import ScoreChart from "../ScoreChart";
import SectionCard from "../SectionCard";
import Strengths from "../Strengths";

export interface DepartmentPageProps {}

const DepartmentPage: React.FC<DepartmentPageProps> = ({}) => {
  const { palette } = useTheme();
  const { college: collegeId, department: departmentId } = useParams();
  const nav = useNavigate();
  const {
    data: instructorData,
    error: instructorError,
    loading: instructorLoading,
  } = useInstructors({
    variables: {
      filter: {
        scope: {
          department: departmentId || "",
        },
        skip: 0,
        take: 100,
      },
    },
  });

  const {
    data: collegeData,
    error: collegeError,
    loading: collegeLoading,
  } = useCollege({
    variables: {
      id: collegeId || "",
    },
  });

  const {
    data: departmentData,
    error: departmentError,
    loading: departmentLoading,
  } = useDepartment({
    variables: {
      id: departmentId || "",
    },
  });

  const college = collegeData?.college || null;
  const department = departmentData?.department || null;

  const [scrolled, setScrolled] = React.useState(false);

  return (
    <Dashboard
      error={departmentError || collegeError}
      loading={departmentLoading || collegeLoading}
      onScroll={(e) => {
        setScrolled(e);
      }}
    >
      {college && department && (
        <Box sx={{ display: "flex", flexFlow: "column", gap: pageSpacing }}>
          <Box
            sx={{
              position: "sticky", // Set the component to be sticky
              top: 0, // This means the component will stick to the top
              zIndex: 1, // Optional: depending on your other components
              backgroundColor: palette.background.body,
              boxShadow: scrolled
                ? "0px 8px 6px -6px rgba(0,0,0,0.1)"
                : undefined,
            }}
          >
            <Box p={2} />
            <BreadCrumbs
              elements={[
                { label: college.name, path: `/${college._id}` },
                {
                  label: department.name,
                  path: `/${college._id}/${department._id}`,
                },
              ]}
            />
            <Box p={1} />
            <PageTitle
              subtitle={`Lead by ${department.leader.name}`}
              avatars={[
                {
                  alt: department.name,
                  src: `https://ui-avatars.com/api/?name=${encodeURI(
                    department.name
                  )}&bold=true&color=fff&background=CC0000`,
                },
                {
                  alt: department.leader.name,
                  src: department.leader.photo,
                },
              ]}
            >
              {department.name}
            </PageTitle>
            <Box p={1} />
            <Divider />
          </Box>
          <PageTitle level={2}>Highlights</PageTitle>
          <Strengths
            filter={{ college: college._id, department: department._id }}
          />
          <Box p={0} />
          <PageTitle level={2}>Average Feedback Scores</PageTitle>
          <ScoreChart
            filter={{
              college: college._id,
              department: department._id,
              skip: 0,
              take: 1,
            }}
          />
          <Box p={0} />
          <PageTitle level={2}>Feedback Distribution</PageTitle>
          <FeedbackDistrubition
            filter={{
              college: college._id,
              department: department._id,
              skip: 0,
              take: 1,
            }}
          />
          <Box p={0} />
          <Divider />
          <Box>
            <PageTitle level={2}>Instructors</PageTitle>
            <Box p={2} />
            {instructorLoading ? (
              <Skeleton />
            ) : (
              <Box>
                {(instructorData?.instructorList.data || []).map(
                  (instructor) => (
                    <SectionCard
                      key={instructor._id}
                      title={instructor.name}
                      avatars={[
                        {
                          alt: instructor.name,
                          src: instructor.photo,
                        },
                      ]}
                      onClick={() =>
                        nav(
                          `/${college._id}/${department._id}/${instructor._id}`
                        )
                      }
                    />
                  )
                )}
              </Box>
            )}
          </Box>
          <Box p={10} />
          <Chat filter={{ college: college._id, department: department._id }}>
            {(loading, error, onClick) => (
              <Box
                sx={{
                  position: "fixed",
                  bottom: "72px",
                  right: "72px",
                  zIndex: 10,
                }}
              >
                <Tooltip title={error ? error.message : ""}>
                  <Button
                    size='lg'
                    onClick={onClick}
                    loading={loading}
                    disabled={Boolean(error)}
                  >
                    Ask a question
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Chat>
        </Box>
      )}
    </Dashboard>
  );
};

export default DepartmentPage;
