import { Box, Button, Divider, Menu, MenuItem, useTheme } from "@mui/joy";
import React from "react";
import Controls from "./components/Controls";
import Header from "./components/Header";
import RouteSection from "./components/RouteSection";

const Sidebar: React.FC = () => {
  const { palette } = useTheme();

  const versionText = process.env.PUBLIC_URL;
  const version = versionText ? parseInt(versionText.replace("/v", "")) : 1;
  const versions = [1, 2, 3, 4, 5, 6];

  const ref = React.useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        background: palette.background.level1,
        minWidth: "300px",
      }}
    >
      <Header />
      <Box sx={{ p: 1 }}>
        <Button
          onClick={() => setOpen(!open)}
          ref={ref}
          size='sm'
          fullWidth
          variant='plain'
          color='neutral'
        >
          Version {version}
        </Button>
        <Menu
          sx={{ width: "284px" }}
          placement='bottom'
          open={open}
          anchorEl={ref.current}
          onClose={() => setOpen(false)}
        >
          {versions.map((v, i) => (
            <MenuItem
              color='neutral'
              selected={v === version}
              variant={v === version ? "soft" : "plain"}
              key={"versionButton_" + i}
              onClick={() => {
                window.location.href = `https://sparkutah.com/v${v}`;
              }}
            >
              Version {v}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Divider />
      <RouteSection />
      <Box sx={{ flex: 1 }} />
      <Controls />
    </Box>
  );
};

export default Sidebar;
