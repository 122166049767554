import { ReactElement, ReactNode } from "react";
import Home from "../../Scenes/Home";
import { BiGrid } from "react-icons/bi";

// The private, protected routes of the app
export interface AppRoute {
  path: string;
  element: ReactElement;
  icon: ReactNode;
  label: string;
}

export const routes: AppRoute[] = [
  {
    path: "/*",
    element: <Home />,
    label: "Student Feedback",
    icon: <BiGrid />,
  },
];
