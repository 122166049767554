import { Avatar, AvatarGroup, AvatarProps, Box, Typography } from "@mui/joy";
import React from "react";

export interface PageTitleProps {
  children: string;
  subtitle?: string;
  level?: 1 | 2 | 3;
  avatars?: AvatarProps[];
}

const PageTitle: React.FC<PageTitleProps> = ({
  children,
  subtitle,
  level = 1,
  avatars,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      {avatars && avatars.length > 0 && (
        <AvatarGroup size={level === 1 ? "lg" : level === 2 ? "md" : "sm"}>
          {avatars.map((av, index) => (
            <Avatar key={"av_" + index} {...av} />
          ))}
        </AvatarGroup>
      )}
      <Box>
        <Typography
          level={level === 1 ? "h2" : level == 2 ? "h3" : "h4"}
          sx={{ fontWeight: 800 }}
        >
          {children}
        </Typography>
        {subtitle && (
          <Typography
            level={level === 1 ? "body1" : level == 2 ? "body2" : "body3"}
            sx={{ fontWeight: 600 }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PageTitle;
