import { useFeedbackCount } from "../../../../GraphQL/Feedback/Feedback";
import { FeedbackFilter } from "../../../../GraphQL/Feedback/FeedbackFilter";

export const useFeedbackDistribution = (
  filter: FeedbackFilter
): {
  loading: boolean;
  error?: Error | null;
  data: null | {
    d10: number;
    d20: number;
    d30: number;
    d40: number;
    d50: number;
    d60: number;
    d70: number;
    d80: number;
    d90: number;
    d100: number;
  };
} => {
  const d10 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 0,
          max: 10,
        },
      },
    },
  });

  const d20 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 10,
          max: 20,
        },
      },
    },
  });

  const d30 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 20,
          max: 30,
        },
      },
    },
  });

  const d40 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 30,
          max: 40,
        },
      },
    },
  });

  const d50 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 40,
          max: 50,
        },
      },
    },
  });

  const d60 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 50,
          max: 60,
        },
      },
    },
  });

  const d70 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 60,
          max: 70,
        },
      },
    },
  });

  const d80 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 70,
          max: 80,
        },
      },
    },
  });

  const d90 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 80,
          max: 90,
        },
      },
    },
  });

  const d100 = useFeedbackCount({
    variables: {
      filter: {
        ...filter,
        avergae_score: {
          min: 90,
          max: 100,
        },
      },
    },
  });

  const loading =
    d10.loading ||
    d20.loading ||
    d30.loading ||
    d40.loading ||
    d50.loading ||
    d60.loading ||
    d70.loading ||
    d80.loading ||
    d90.loading ||
    d100.loading;

  const error =
    d10.error ||
    d20.error ||
    d30.error ||
    d40.error ||
    d50.error ||
    d60.error ||
    d70.error ||
    d80.error ||
    d90.error ||
    d100.error;

  const data =
    d10.data &&
    d20.data &&
    d30.data &&
    d40.data &&
    d50.data &&
    d60.data &&
    d70.data &&
    d80.data &&
    d90.data &&
    d100.data
      ? {
          d10: d10.data.feedbackList.total,
          d20: d20.data.feedbackList.total,
          d30: d30.data.feedbackList.total,
          d40: d40.data.feedbackList.total,
          d50: d50.data.feedbackList.total,
          d60: d60.data.feedbackList.total,
          d70: d70.data.feedbackList.total,
          d80: d80.data.feedbackList.total,
          d90: d90.data.feedbackList.total,
          d100: d100.data.feedbackList.total,
        }
      : null;

  return {
    loading,
    error,
    data,
  };
};
