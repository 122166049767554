import { Box, Typography, useTheme } from "@mui/joy";
import React from "react";
import Message from "../../../../Components/Feedback/Message";
import BulletList from "../../../../Components/Layout/BulletList";
import Skeleton from "../../../../Components/Layout/Skeleton";
import { useFeedbackSummary } from "../../../../GraphQL/FeedbackSummary/FeedbackSummary";
import { FeedbackSummaryFilter } from "../../../../GraphQL/FeedbackSummary/FeedbackSummaryFilter";

export interface StrengthsProps {
  filter: FeedbackSummaryFilter;
}

const Strengths: React.FC<StrengthsProps> = ({ filter }) => {
  const { palette } = useTheme();

  const { data, error, loading } = useFeedbackSummary({
    variables: {
      filter,
    },
  });

  if (loading) return <Skeleton />;
  if (error) return <Message message={error.message} type='error' />;
  if (!data) return <Message message='No data found' type='error' />;

  const summary = data.feedbackSummary;

  return (
    <Box sx={{ display: "flex", flexFlow: "column" }}>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box>
          <Typography
            level='h6'
            sx={{ fontWeight: 700, color: palette.success[500] }}
          >
            <em>What's working</em>
          </Typography>
          <BulletList>{summary.strengths}</BulletList>
        </Box>
        <Box>
          <Typography
            level='h6'
            sx={{ fontWeight: 700, color: palette.danger[500] }}
          >
            <em>What needs work</em>
          </Typography>
          <BulletList>{summary.weaknesses}</BulletList>
        </Box>
      </Box>
    </Box>
  );
};

export default Strengths;
