import { gql } from "@apollo/client";
import { Department, DepartmentFragment } from "../Department/Department";
import { Division } from "../Division/Division";
import { getQueryHook } from "../getQueryHook";
import { List } from "../List/List";
import { PersonFragment } from "../Person/Person";
import { CollegeFilter } from "./CollegeFilter";

export interface College extends Division {
  departments: Department[];
}

export const CollegeFragment = gql`
  fragment CollegeFragment on College {
    _id
    name
    leader {
      ...PersonFragment
    }
    departments {
      ...DepartmentFragment
    }
  }
`;

export const CollegeListQuery = gql`
  ${CollegeFragment}
  ${DepartmentFragment}
  ${PersonFragment}
  query CollegeList($filter: CollegeFilter!) {
    collegeList(filter: $filter) {
      total
      data {
        ...CollegeFragment
      }
    }
  }
`;

export const CollegeQuery = gql`
  ${DepartmentFragment}
  ${PersonFragment}
  query College($id: ObjectId!) {
    college(id: $id) {
      _id
      departments {
        ...DepartmentFragment
      }
      leader {
        ...PersonFragment
      }
      name
    }
  }
`;

export const useColleges = getQueryHook<
  { collegeList: List<College> },
  { filter: CollegeFilter }
>(CollegeListQuery);

export const useCollege = getQueryHook<{ college: College }, { id: string }>(
  CollegeQuery
);
